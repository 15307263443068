var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main",class:_vm.show ? 'h5' : ''},[_c('div',{staticClass:"htop"},[_c('mtop')],1),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"main_box"},[_c('div',{staticClass:"box6"},[_c('h1',{staticClass:"right_title"},[_vm._v("招聘人才")]),_c('div',{staticClass:"box_cont"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"item",on:{"click":function($event){return _vm.toPage(item)}}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"btn"},[_vm._v("共"+_vm._s(item.content_count)+"个职位")])])}),0)])]),_c('mbottom')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('img',{staticClass:"simg",attrs:{"src":require("../assets/ximg/banner-gywm@2x.png"),"alt":"szzc"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main_box"},[_c('div',{staticClass:"box1"},[_c('div',{staticClass:"left_con"},[_c('div',{staticClass:"title"},[_vm._v("星润科技")]),_c('div',{staticClass:"cont"},[_vm._v(" 星润科技-国内领先的区块链技术服务商，公司成立于2013年，是一家致力于为企业提供数字化转型的区块链技术服务公司，全力打造国内首个元宇宙生态应用服务系统，已开发完成星科数藏saas系统、元宇宙数字空间+虚拟数字人搭建、星科联盟链等行业元宇宙解决方案。 ")]),_c('div',{staticClass:"cont"},[_vm._v(" 公司一直秉持着“让技术更可信，让营销更简单”的信念，始终以自主创新的技术、高效卓越的产品应用；坚持不懈地为客户数字化转型发展提供全面的技术支持，推动元宇宙生态高质量发展，加速企业数字化转型进程。 ")])]),_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../assets/ximg/tp-qiye.jpg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about_box"},[_c('div',{staticClass:"box2"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../assets/ximg/icon-sm.png"),"alt":""}})]),_c('div',{staticClass:"cont"},[_c('div',{staticClass:"title"},[_vm._v("使命")]),_c('div',{staticClass:"txt"},[_vm._v("为加速企业数字化进程努力奋斗")]),_c('div',{staticClass:"txt"},[_vm._v(" 为早日实现国家数字中国战略贡献一份力量 ")])])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../assets/ximg/icon-yj.png"),"alt":""}})]),_c('div',{staticClass:"cont"},[_c('div',{staticClass:"title"},[_vm._v("愿景")]),_c('div',{staticClass:"txt"},[_vm._v("让技术更可信，让营销更简单")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main_box"},[_c('div',{staticClass:"box3"},[_c('h1',{staticClass:"right_title"},[_vm._v("员工天地")]),_c('h2',{staticClass:"right_tip"},[_vm._v("与员工携手创造辉煌")]),_c('div',{staticClass:"right_con"},[_c('img',{attrs:{"src":require("../assets/ximg/tp-ygtd.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main_box"},[_c('img',{staticClass:"mbg",attrs:{"src":require("@/assets/ximg/jlzz.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"enter_box"},[_c('div',{staticClass:"Gtitle"},[_c('h1',[_vm._v("企业发展")]),_c('p',[_vm._v("九年稳建运营 砥砺前行")])]),_c('img',{staticClass:"fzbg",attrs:{"src":require("@/assets/images/qyfz.png"),"alt":"企业发展"}}),_c('ul',{staticClass:"Gwidth"},[_c('li',[_c('h4',[_vm._v("2013年")]),_c('p',[_vm._v("公司成立微营销论坛全国巡讲")])]),_c('li',[_c('h4',[_vm._v("2014年")]),_c('p',[_vm._v("微客赚移动电商saas系统上线")])]),_c('li',[_c('h4',[_vm._v("2015年")]),_c('p',[_vm._v("微客赚餐饮系统发布")])]),_c('li',[_c('h4',[_vm._v("2016年")]),_c('p',[_vm._v("微客赚知识付费系统发布")])]),_c('li',[_c('h4',[_vm._v("2017年")]),_c('p',[_vm._v("微客赚短视频带货系统发布进军区块链技术领域")])]),_c('li',[_c('h4',[_vm._v("2018年")]),_c('p',[_vm._v("微客赚短视频带货系统发布 链改服务平台-通证派")])]),_c('li',[_c('h4',[_vm._v("2019年")]),_c('p',[_vm._v("微客赚小程序直播系统上线 IPFS分布式存储集群搭建")])]),_c('li',[_c('h4',[_vm._v("2020年")]),_c('p',[_vm._v("星科算力系统上线")])]),_c('li',[_c('h4',[_vm._v("2021年")]),_c('p',[_vm._v("聚焦区块链技术服务 拓展NFT（数藏）业务板块")])]),_c('li',[_c('h4',[_vm._v("2022年")]),_c('p',[_vm._v("星科数藏saas系统上线 布局元宇宙数字人等相关业务")])])])])
}]

export { render, staticRenderFns }