<template>
	<div class="main" :class="show ? 'h5' : ''">
		<div class="htop">
		<mtop />
		</div>
		<div class="banner">
				<img
            src="../assets/ximg/banner-gywm@2x.png"
            alt="szzc"
            class="simg"
          />
		</div>
		<!-- <div class="banner">
			<div class="banimg">
				<div class="title">星润科技-国内领先的区块链技术服务商</div>
				<div class="tip">让技术更可信，让营销更简单</div>
			</div>
		</div> -->
		<div class="main_box">
			<div class="box1">
				<div class="left_con">
					<div class="title">星润科技</div>
					<div class="cont">
						星润科技-国内领先的区块链技术服务商，公司成立于2013年，是一家致力于为企业提供数字化转型的区块链技术服务公司，全力打造国内首个元宇宙生态应用服务系统，已开发完成星科数藏saas系统、元宇宙数字空间+虚拟数字人搭建、星科联盟链等行业元宇宙解决方案。
					</div>
					<div class="cont">
						公司一直秉持着“让技术更可信，让营销更简单”的信念，始终以自主创新的技术、高效卓越的产品应用；坚持不懈地为客户数字化转型发展提供全面的技术支持，推动元宇宙生态高质量发展，加速企业数字化转型进程。
					</div>
				</div>
				<div class="img">
					<img src="../assets/ximg/tp-qiye.jpg" alt="" />
				</div>
			</div>
		</div>
		<div class="about_box">
			<div class="box2">
				<div class="item">
					<div class="img">
						<img src="../assets/ximg/icon-sm.png" alt="" />
					</div>
					<div class="cont">
						<div class="title">使命</div>
						<div class="txt">为加速企业数字化进程努力奋斗</div>
						<div class="txt">
							为早日实现国家数字中国战略贡献一份力量
						</div>
					</div>
				</div>
				<div class="item">
					<div class="img">
						<img src="../assets/ximg/icon-yj.png" alt="" />
					</div>
					<div class="cont">
						<div class="title">愿景</div>
						<div class="txt">让技术更可信，让营销更简单</div>
					</div>
				</div>
			</div>
		</div>
		<div class="main_box">
			<div class="box3">
				<h1 class="right_title">员工天地</h1>
				<h2 class="right_tip">与员工携手创造辉煌</h2>
				<div class="right_con">
					<img src="../assets/ximg/tp-ygtd.png" alt="" />
				</div>
			</div>
		</div>
		<div class="main_box">
			<img src="@/assets/ximg/jlzz.png" alt=""  class="mbg"/>
			<!-- <div class="box4">
				<h1 class="right_title">奖项资质</h1>
				<h2 class="right_tip">荣获行业数十项荣誉和资质</h2>
				<div class="box_cont">
					<div class="img">
						<img src="../assets/ximg/tp-1.png" alt="" />
					</div>
					<div class="img">
						<img src="../assets/ximg/tp-2.png" alt="" />
					</div>
					<div class="img">
						<img src="../assets/ximg/tp-3.png" alt="" />
					</div>
					<div class="img">
						<img src="../assets/ximg/tp-4.png" alt="" />
					</div>
						<div class="img">
						<img src="../assets/ximg/tp-9.png" alt="" />
					</div>				
					<div class="img">
						<img src="../assets/ximg/tp-6.png" alt="" />
					</div>
					<div class="img">
						<img src="../assets/ximg/tp-7.png" alt="" />
					</div>
					<div class="img">
						<img src="../assets/ximg/tp-8.png" alt="" />
					</div>
				</div>
			</div> -->
		</div>
		<div class="enter_box">
			<div class="Gtitle">
				<h1>企业发展</h1>
				<p>九年稳建运营 砥砺前行</p>
			</div>
			<img class="fzbg" src="@/assets/images/qyfz.png" alt="企业发展" />
			<ul class="Gwidth">
				<li>
					<h4>2013年</h4>
					<p>公司成立微营销论坛全国巡讲</p>
				</li>
				<li>
					<h4>2014年</h4>
					<p>微客赚移动电商saas系统上线</p>
				</li>
				<li>
					<h4>2015年</h4>
					<p>微客赚餐饮系统发布</p>
				</li>
				<li>
					<h4>2016年</h4>
					<p>微客赚知识付费系统发布</p>
				</li>
				<li>
					<h4>2017年</h4>
					<p>微客赚短视频带货系统发布进军区块链技术领域</p>
				</li>
				<li>
					<h4>2018年</h4>
					<p>微客赚短视频带货系统发布 链改服务平台-通证派</p>
				</li>
				<li>
					<h4>2019年</h4>
					<p>微客赚小程序直播系统上线 IPFS分布式存储集群搭建</p>
				</li>
				<li>
					<h4>2020年</h4>
					<p>星科算力系统上线</p>
				</li>
				<li>
					<h4>2021年</h4>
					<p>聚焦区块链技术服务 拓展NFT（数藏）业务板块</p>
				</li>
				<li>
					<h4>2022年</h4>
					<p>星科数藏saas系统上线 布局元宇宙数字人等相关业务</p>
				</li>
			</ul>
		</div>
		<div class="main_box">
			<div class="box6">
				<h1 class="right_title">招聘人才</h1>
				<div class="box_cont">
					<div
						class="item"
						v-for="(item, index) in list"
						:key="index"
						@click="toPage(item)"
					>
						<div class="title">{{ item.title }}</div>
						<div class="btn">共{{ item.content_count }}个职位</div>
					</div>
				</div>
			</div>
		</div>
		<mbottom />
	</div>
</template>

<script>
	import { connectTron } from "@/api/Public";
	import mtop from "../components/top.vue";
	import mbottom from "../components/bottom.vue";
	export default {
		data() {
			return {
				heightList: [],
				center: { lng: 111, lat: 111 }, //lng代表纬度 lat代表经度
				zoom: 19,
				firstIndex: 1,
				show: false,
				list: [],
			};
		},

		components: {
			mtop,
			mbottom,
		},

		mounted() {
			if (this._isMobile()) {
				this.show = true;
			} else {
				this.show = this.$store.getters.phone;
			}
		},
		watch: {
			"$store.getters.sid": function (val) {
				this.handleFiexdToolItem(val);
			},
			"$store.getters.phone": function (val) {
				this.show = val;
			},
			deep: true,
			immediate: true,
		},
		created() {
			this.getList();
		},
		methods: {
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				return flag;
			},
			getList() {
				this.$axios({
					method: "get",
					url: "v1/articleTypeList",
					params: { pid: 4 },
				})
					.then((res) => {
						this.list = res.data.data;
						console.log(this.list);
					})
					.catch(() => {
						console.log("请求失败");
					});
			},
			toPage(row) {
				// console.log(row);
				// this.$toast('敬请期待');
				// return
				sessionStorage.setItem("conid", row.id);
				this.$router.push({
					name: "recruiting",
					params: {
						id: row.id,
					},
				});
			},
			clickNav(index) {
				this.firstIndex = index;
				var navPage = document.querySelector(".right_box" + index);
				// const wrap=this.sysWrap.wrap;
				// wrap.scrollTop=navPage.offsetTop;
				window.scrollTo({
					top: navPage.offsetTop,
					behavior: "smooth",
				});
			},
			handler({ BMap, map }) {
				var point = new BMap.Point(111, 111);
				map.centerAndZoom(point, 19);
				var marker = new BMap.Marker(point); // 创建标注
				map.addOverlay(marker); // 将标注添加到地图中
				var circle = new BMap.Circle(point, 1, {
					strokeColor: "Red",
					strokeWeight: 1,
					strokeOpacity: 1,
					Color: "Red",
					fillColor: "#f03",
				});
				map.addOverlay(circle);
			},
			getClickInfo(e) {
				console.log(e.point.lng);
				console.log(e.point.lat);
				this.center.lng = e.point.lng;
				this.center.lat = e.point.lat;
			},

			readDetail() {
				this.$router.replace({ path: "/help2" });
			},
			goHelpMore(i) {
				console.log(i);
				this.$router.replace({ path: "/help1?index=" + i });
			},
			wallet() {
				var i = 0;
				var a = setInterval(() => {
					if (typeof window.ethereum !== "undefined") {
						connectTron().then(
							(info) => {
								clearInterval(a);
								if (info[0].indexOf("0x") == 0) {
									if (
										info[0] !=
										this.$store.getters.userWallet
									) {
										this.buy = true;
									}
									this.$store.commit("userWallet", info[0]);
								} else {
									this.$toast(
										"Use the ETH address to log in to the game"
									);
								}
							},
							(e) => {
								this.$toast(e.message);
							}
						);
					}
					if (i > 5) {
						clearInterval(a);
					}
					i++;
				}, 1000);
			},

			tobuy() {
				location.href =
					"https://opensea.io/collection/cupid-love-metaverse";
			},
			up() {
				if (this.sqloading) {
					return;
				}
				this.buynum = Number(this.buynum) + 1;
			},
			dow() {
				if (this.sqloading) {
					return;
				}
				this.buynum = this.buynum <= 1 ? 1 : Number(this.buynum) - 1;
			},
			close() {
				this.shows = false;
			},
			handleFiexdToolItem(e) {
				if (!e) {
					return;
				}
				this.$nextTick(() => {
					document.getElementById(e).scrollIntoView({
						behavior: "smooth",
						block: "start",
						inline: "nearest",
					});
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	$t-mf: "Arial";
	.main {
		background: #ffffff;
		min-height: 100vh;
		max-width: 100%;
		min-width: 1200px;
		width: 100%;
		.htop{
		    height: 70px;
	    }	
        .banner{
    width: 100%;
    font-size: 0;
    .simg{
        width: 100%;
        height: auto;
    }
  }
		// .banner {
		// 	max-width: 100%;
		// 	min-width: 1200px;
		// 	width: 100%;
		// 	margin-bottom: 40px;
		// 	.banimg {
		// 		max-width: 100%;
		// 		min-width: 1200px;
		// 		width: 100%;
		// 		height: 510px;
		// 		position: relative;
		// 		text-align: left;
		// 		background: url("../assets/ximg/banner3.jpg");
		// 		background-size: cover;
		// 		display: flex;
		// 		flex-direction: column;
		// 		align-items: center;
		// 		justify-content: center;
		// 		.title {
		// 			width: 1200px;
		// 			font-size: 36px;
		// 			font-family: Microsoft YaHei;
		// 			font-weight: 400;
		// 			color: #ffffff;
		// 		}
		// 		.tip {
		// 			width: 1200px;
		// 			font-size: 16px;
		// 			font-family: Microsoft YaHei;
		// 			font-weight: 400;
		// 			color: #ffffff;
		// 			margin-top: 23px;
		// 		}
		// 	}
		// }
		.main_box {
			width: 100%;
			background: #fff;
			box-sizing: border-box;
			// padding: 32px 28px;
			display: flex;
			flex-direction: column;
			text-align: left;
			cursor: default;
			.mbg{width: 100%;height: auto;}
			.box1 {
				display: flex;
				margin-top: 45px;
				justify-content: space-between;
				width: 1200px;
				margin: 0 auto;
				padding: 80px 0;
				.img {
					img {
						width: 600px;
						height: 340px;
					}
				}
				.left_con {
					display: flex;
					flex-direction: column;
					margin-right: 37px;
					.title {
						font-size: 28px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						margin: 45px 0;
					}
					.cont {
						width: 556px;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #333333;
						line-height: 23px;
						text-align: left;
						margin-bottom: 23px;
					}
				}
			}
			.box4 {
				display: flex;
				margin-top: 45px;
				justify-content: center;
				width: 1200px;
				margin: 80px auto;
				align-items: center;
				flex-direction: column;
				// background-color: #f5f6fa;
				.right_title {
					font-size: 28px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
				}
				.right_tip {
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #999999;
				}
				.box_cont {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					flex-wrap: wrap;
					margin-top: 36px;
					.img {
						width: 280px;
						height: 186px;
						background: #ffffff;
						border-radius: 4px;
						border: 1px solid #dcdee0;
						margin: 12px 0;
						display: flex;
						align-items: center;
						justify-content: center;
						img {
							width: 250px;
							height: 166px;
						}
					}
				}
			}
		}
		.about_box {
			width: 100%;
			background: #f4f6fe;

			.box2 {
				width: 1200px;
				height: 329px;
				margin: 0 auto;
				display: flex;
				align-items: center;
				justify-content: space-around;
				.item {
					display: flex;
					align-items: flex-start;
					.img {
						img {
							width: 129px;
							height: 129px;
						}
					}
					.cont {
						margin-left: 54px;
						.title {
							font-size: 24px;
							font-family: PingFangSC-Medium, PingFang SC;
							font-weight: 500;
							color: #1d2129;
							line-height: 33px;
							text-align: left;
							margin-bottom: 24px;
						}
						.txt {
							font-size: 16px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #646566;
							text-align: left;
						}
					}
				}
			}
		}

		.box3 {
			margin-top: 60px;
			text-align: center;
			.right_title {
				font-size: 28px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
			}
			.right_tip {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #999999;
			}
			.right_con {
				display: flex;
				margin-top: 35px;
				justify-content: center;
				img {
					width: 1200px;
				}
			}
		}
		.box6 {
			text-align: center;
			background: #f4f6fe;
			//  margin-top:50px;
			.right_title {
				margin-top: 50px;
				font-size: 28px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
			}
			.right_tip {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #999999;
				margin-bottom: 40px;
				// line-height: 60px;
			}
			.box_cont {
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;
				width: 1226px;
				margin: 0 auto 80px;
				.item {
					width: 280px;
					height: 186px;
					background: #ffffff;
					border-radius: 4px;
					border: 1px solid #dcdee0;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					box-sizing: border-box;
					margin: 13px;
					.title {
						font-size: 18px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #000000;
						line-height: 25px;
					}
					.btn {
						min-width: 122px;
						height: 40px;
						background: #ffffff;
						border-radius: 2px;
						font-size: 16px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #2b6bff;
						line-height: 40px;
						margin-top: 20px;
						border: 1px solid #2b6bff;
						cursor: pointer;
					}
				}
				.item:hover {
					box-shadow: 0px 6px 16px 0px rgba(62, 73, 96, 0.15);
				}
			}
		}
	}
	.h5 {
		min-width: 100%;
		.htop{
            height:125px;
        }
        .banner{
    width: 100%;    
    font-size: 0;
    display: flex;
        align-items: center;
        justify-content: center;
    .simg{
        width: 1000px;
        height: auto;
    }
  }
		.main_box {
			.box1 {
				width: 100%;
				flex-direction: column;
				align-items: center;
				padding: 32px 0;

				.left_con {
					margin: 0 37px;
					.title {
						font-size: 36px;
						text-align: center;
						font-weight: bold;
					}
					.cont {
						font-size: 26px;
						line-height: 42px;
						margin: 0 auto 40px;
						width: calc(100% - 64px);
					}
				}
			}
			.box4 {
				width: 100%;
				.right_title {
					font-size: 36px;
					font-weight: bold;
				}
				.right_tip {
					font-size: 26px;
					margin: 10px auto;
				}
				.box_cont {
					padding: 32px 28px;
					width: calc(100% - 56px);
					.img {
						width: calc(50% - 30px);
					}
				}
			}
		}
		.box3 {
			.right_title {
				font-size: 36px;
				font-weight: bold;
			}
			.right_tip {
				font-size: 26px;
				margin: 10px auto;
			}
			.right_con {
				padding: 32px 28px;
				img {
					width: 100%;
					height: 100%;
				}
			}
		}

		.about_box {
			.box2 {
				width: 100%;
				flex-direction: column;
				.item {
					width: calc(100% - 60px);
					margin: 0 auto;
					align-items: center;
					.cont {
						.title {
							font-size: 32px;
							margin: 10px 0;
						}
						.txt {
							font-size: 26px;
							height: 36px;
						}
					}
				}
			}
		}
		.box6 {
			.box_cont {
				width: calc(100% - 56px);
				margin: 50px auto;
				.item {
					width: calc(50% - 26px);
					.title {
						font-size: 26px;
						line-height: 42px;
					}
					.btn {
						font-size: 24px;
					}
				}
			}
		}
	}

	@media screen and (min-width: 0px) {
		.Gtitle {
			text-align: center;
			h1 {
				font-size: 24px;
				font-weight: 500;
				color: #000000;
				line-height: 45px;
			}
			p {
				font-size: 14px;
				font-weight: 400;
				color: #969799;
				line-height: 22px;
			}
		}
		.enter_box {
			position: relative;
			padding: 30px 0;

			.fzbg {
				display: none;
				width: 100%;
			}
			ul {
				margin-top: 30px;
				li {
					margin-bottom: 40px;
					padding-left: 60px;
					position: relative;
					z-index: 1;
					&:last-child {
						&::before {
							display: none;
						}
					}
					&::after {
						content: "";
						width: 30px;
						height: 30px;
						border-radius: 50%;
						position: absolute;
						left: 10px;
						top: 0px;
						background-color: #f2f6fe;
						z-index: -1;
					}
					&::before {
						content: "";
						width: 1px;
						height: calc(100% + 20px);
						position: absolute;
						left: 25px;
						top: 0px;
						background-color: #2b6bff;
						z-index: -1;
					}
					h4 {
						font-size: 18px;
						font-weight: 500;
						color: #1d2129;
						&::after {
							content: "";
							width: 10px;
							height: 10px;
							border-radius: 50%;
							position: absolute;
							left: 20px;
							top: 10px;
							background-color: #2b6bff;
						}
					}
					p {
						margin-top: 10px;
						font-size: 16px;
						font-weight: 400;
						color: #646566;
					}
				}
			}
		}
	}
	@media screen and (min-width: 760px) {
	}
	@media screen and (min-width: 820px) {
	}
	@media screen and (min-width: 1100px) {
		.Gtitle {
			h1 {
				font-size: 32px;
			}
			p {
				font-size: 16px;
				margin-top: 14px;
			}
		}
		.enter_box {
			padding: 0;
			margin-top: 80px;
			.Gtitle {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
			}
			ul {
				display: none;
			}
			.fzbg {
				display: block;
			}
		}
	}
	@media screen and (min-width: 1300px) {
	}
</style>
